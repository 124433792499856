import React, { useState } from 'react';
import { v4 } from "uuid";
import { Link } from "gatsby"
import { getUpdatedItems } from "../../../utils/functions";
import { LazyLoadImage } from "react-lazy-load-image-component";
import cartSpinnerGif from '../../../images/cart-spinner.gif';
import './style.scss';
import isEmpty from "validator/es/lib/isEmpty";
import DataSingleRemove from '../../data-layer/remove-single-cart'
import DataIncrease from '../../data-layer/cart-increase'

const CartItem = ( {
	                   item,
	                   products,
	                   updateCartProcessing,
	                   handleRemoveProductClick,
	                   updateCart,
                   } ) => {

	const [productCount, setProductCount] = useState( item.qty );

	/*
	 * When user changes the qty from product input update the cart in localStorage
	 * Also update the cart in global context
	 *
	 * @param {Object} event event
	 *
	 * @return {void}
	 */
	const handleQtyChange = ( event, item, cartKey, type ) => {

		if ( process.browser ) {

			event.stopPropagation();
			let newQty;

			// If the previous update cart mutation request is still processing, then return.
			if ( updateCartProcessing || ( 'decrement' === type && 1 === productCount ) ) {
				return;
			}
			if ( !isEmpty( type ) ) {
				if('increment' === type){
					newQty = productCount + 1
					DataIncrease(item)
				} else {
					if(productCount !== 1){
					newQty = productCount - 1
					DataSingleRemove(item)
				} else {
					newQty = productCount
				}
				}




			}
 		else {
				// If the user tries to delete the count of product, set that to 1 by default ( This will not allow him to reduce it less than zero )
				newQty = ( event.target.value ) ? parseInt( event.target.value ) : 1;
			}

			// Set the new qty in state.
			setProductCount( newQty );

			if ( products.length ) {

				const updatedItems = getUpdatedItems( products, newQty, cartKey );

				updateCart( {
					variables: {
						input: {
							clientMutationId: v4(),
							items: updatedItems
						}
					},
				} );
			}

		}
	};


	return (
		<div className="row cart-items-wrap">
			<div className="col-md-3 cart-left-col">
				<figure>
					<LazyLoadImage
						alt={item.image.title}
						src={! isEmpty( item.image.sourceUrl ) ? item.image.sourceUrl : ''} // use normal <img> attributes as props
						effect="blur"
					/>
				</figure>
			</div>

			<div className="col-md-9 cart-right-col">
				<div className="cart-product-title-wrap">
					<h2 className="cart-product-title" style={{maxWidth:'90%'}}><Link className="spec-h" to={item.link}> {item.name }</Link></h2>
					<button className="btn btn-danger cart-remove-item" onClick={ ( event ) => handleRemoveProductClick( event, item, item.cartKey, products ) }>x</button>

					{item.variation ?
						<>
						{item.variation.attributes.map((item, i) => {
						return (
							<small style={{border:'1px solid #111',borderRadius:'5px',padding:'5px',margin:'15px 5px'}}>{item.value}</small>
						)
						})}
						</>
						:null
					}

				</div>

				<footer className="cart-product-footer">
					{/*Qty*/}
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<button className="increment-btn" onClick={( event ) => handleQtyChange( event, item, item.cartKey, 'decrement' )} >-</button>
						<input
							type="number"
							min="1"
							style={{ textAlign: 'center', width: '60px', paddingRight: '0' }}
							data-cart-key={ item.cartKey }
							className={ `woo-next-cart-qty-input form-control ${ updateCartProcessing ? 'woo-next-cart-disabled' : '' } ` }
							value={ productCount }
							onChange={ ( event ) => handleQtyChange( event, item, item.cartKey, '' ) }
						/>
						<button className="decrement-btn" onClick={( event ) => handleQtyChange( event, item, item.cartKey, 'increment' )}>+</button>
						{ updateCartProcessing ?
							<img className="woo-next-cart-item-spinner" src={ cartSpinnerGif } alt="spinner"/> : '' }
					</div>
					<div className="">
						{/*<span className="cart-product-price">{ ( 'string' !== typeof item.price ) ? item.price : item.price }</span>*/}
						<span style={{fontSize:'14px'}} className="cart-total-price"> {( 'string' !== typeof item.totalPrice ) ? item.totalPrice + ' | '+item.totalEuPrice : item.totalPrice + ' | '+item.totalEuPrice }</span>
					</div>
				</footer>
			</div>
		</div>
	)
};

export default CartItem;
