
function DataRemove(props) {

window.dataLayer.push({ ecommerce: null });

let items=[]

  props.products.forEach((item, i) => {

    let product =  {
       "item_id": item.id,
       "item_name": item.name,
       "price":  parseFloat(Number(item.price.replace('zł','')).toFixed(2)),
       "quantity": item.qty,
       "item_brand": "Shroom",
       "item_category": item.productCategories ? item.productCategories.nodes[0].name : '',
     }
     items.push(product)
  });


  window.dataLayer.push({
     "event":  "remove_from_cart",
     "ecommerce":  {
      "value": parseFloat(Number(props.totalProductsPrice.replace('zł','')).toFixed(2)),
      "currency": "PLN",
      "items": items
    }
  });

}



export default DataRemove;
