import React, { useEffect } from "react"
import Layout from "../../components-pl/layout";
import SEO from "../../components-pl/seo";
import CartItemsContainer from "../../components-pl/cart/cart-items-container";
import SliderDrinks from "../../components-pl/slider-drinks";

const Cart = () => {
	useEffect(() => {
	document.getElementById('lang-switch').href="/cart/"
	}, []);
	return (
		<Layout>
		<SEO
			title={ 'Koszyk' }
			header={ { siteTitle: ' Shroom | Sklep' } }
		/>
			<CartItemsContainer/>
			<div style={{backgroundColor:'#e8d3d0'}}>
			<br /><br />
			<SliderDrinks />
			<br /><br />
			</div>
		</Layout>
	)
};

export default Cart;
